import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

// import Dashbord from "./Components/Dashbord";
import NavigationMenu from "./Components/Dashbord/NavigationMenu";
import City from "./Components/City/City";
import Country from "./Components/Country/Country";
import Dealer from "./Components/Dealer/Dealer";
import SugarMill from "./Components/SugarMill/SugarMill";
import Unit from "./Components/Unit/Unit";
import NOC from "./Components/Transition/NOC/NOC";
import Approval from "./Components/Transition/Approval/Approval";
import Noc from "./Components/Reports/Noc/Noc";
import NocSummery from "./Components/Reports/NocSummery/NocSummery";
import State from "./Components/State/State";
import LoginPage from "./Components/Login/LoginPage";
import "./App.css";
import Destination from "./Components/Destination/Destination";
import UserRegister from "./Components/Regiesteration/UserRegister";
import { Footer } from "./Components/Footer/Footer";
import District from "./Components/District/District";
import DistrictQuota from "./Components/DistrictQuota/DistrictQuota";
import Designation from "./Components/Designation/Designation";

export const urlApi = "https://icdq.pk";

function App() {
  useEffect(() => {
    const checkExpiryTime = () => {
      let expiresAt = localStorage.getItem("expiresAt");
  
      // If expiresAt is not set, set it to 24 hours from now
      if (!expiresAt) {
        const expiryTime = new Date();
        expiryTime.setHours(expiryTime.getHours() + 24); // Set expiry time to 24 hours from now
        expiresAt = expiryTime.toISOString(); // Save as ISO string to localStorage
        localStorage.setItem("expiresAt", expiresAt); // Save this new expiry time in localStorage
      }
  
      // If the expiry time exists and the current time is past the expiry time
      if (expiresAt && new Date(expiresAt) <= new Date()) {
        handleLogout(); // Call logout function
      }
    };
  
    // Check on initial load
    checkExpiryTime();
  
    // Set an interval to check the expiry time every second
    const interval = setInterval(checkExpiryTime, 1000); // Check every second
  
    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  
  }, []);// Empty dependency array ensures it runs only on mount
  const storedUserData = JSON.parse(localStorage.getItem("userData"));

  const [isLoggedIn, setIsLoggedIn] = useState(!!storedUserData);
  const [userData, setUserData] = useState(storedUserData);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activeScreen, setActiveScreen] = useState("");
  // const [navOpen, setNavOpen] = useState(true);

  const handleLogin = (userData) => {
    setIsLoggedIn(true);

    setUserData(userData.userInput);

    localStorage.setItem("userData", JSON.stringify(userData.userInput));
  };

  const   handleLogout = () => {
    setIsLoggedIn(false);
    setUserData(null);
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    localStorage.removeItem("menuItems");
    localStorage.removeItem("signImg");
    localStorage.removeItem("currentScreeen");
    localStorage.removeItem("expiresAt");
    setActiveScreen("");
  };

  const toggleSidebar = (isNavOpen) => {
    setIsSidebarOpen(isNavOpen);
  };

  const LinkFunc = (screenname) => {
    localStorage.setItem("currentScreeen", screenname);
    setActiveScreen(screenname);
  };

  return (
    <>
      <div className={`app-container  ${isSidebarOpen ? "sidebar-open" : ""}`}>
        <Router>
          {isLoggedIn ? (
            <>
              <main>
                <header>
                  <NavigationMenu
                    onLogout={handleLogout}
                    SideBarValue={toggleSidebar}
                    userInput={userData}
                    onLinkClick={LinkFunc}
                    // userPassword={userData.userPassword}
                  />
                </header>
                <section
                  style={{
                    paddingLeft: isSidebarOpen ? "0" : "190px",
                    overflowX: "hidden",
                  }}
                >
                  <div className="heading_button">
                    <div className="heading">
                      <p>{activeScreen}</p>
                    </div>
                  </div>
                  <Routes>
                    {/* <Route path="/" element={<Dashbord />} /> */}
                    <Route path="/master/country" element={<Country />} />
                    <Route path="/master/state" element={<State />} />
                    <Route path="/master/district" element={<District />} />
                    <Route path="/master/city" element={<City />} />
                    <Route
                      path="/master/destination"
                      element={<Destination />}
                    />
                    <Route
                      path="/master/qistrictquota"
                      element={<DistrictQuota />}
                    />
                    <Route path="/master/dealer" element={<Dealer />} />
                    <Route path="/master/sugarmill" element={<SugarMill />} />
                    <Route path="/master/unit" element={<Unit />} />
                    <Route
                      path="/master/designation"
                      element={<Designation />}
                    />
                    <Route path="/transaction/noc" element={<NOC />} />
                    <Route
                      path="/transaction/approval"
                      element={<Approval />}
                    />
                    <Route path="/reports/noc" element={<Noc />} />
                    <Route
                      path="/reports/nocsummery"
                      element={<NocSummery />}
                    />
                    <Route
                      path="/settings/userrights"
                      element={<UserRegister />}
                    />
                  </Routes>
                </section>
              </main>
            </>
          ) : (
            <Routes>
              <Route path="/" element={<LoginPage onLogin={handleLogin} />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          )}
        </Router>
      </div>
      <Footer />
    </>
  );
}

export default App;
